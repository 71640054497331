import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { GiDiceTarget } from "react-icons/gi";
import { CgOrganisation } from 'react-icons/cg';

ChartJS.register(...registerables);

const OrganicPaid = () => {
    const [data] = useState({
        labels: ['Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
            {
                label: 'Organic',
                backgroundColor: '#001633',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 100],
            },
            {
                label: 'Paid',
                backgroundColor: '#ffca09',
                borderColor: 'rgb(71, 225, 167)',
                data: [48, 78, 10, 99, 46, 87, 20],
            },
        ],
    });
    return (
        <div className="bg-white">
            <Bar data={data} options={{ responsive: true }} />
            <div className="organicPaid">
                <div className="main">
                    <div className="nameicons">
                        <div className="oicons">
                            <GiDiceTarget />
                        </div>
                        <div className="oname">
                            <p>Organic Sales</p>
                            <small>Website</small>
                        </div>
                    </div>
                    <div className="ocounter">
                        <p>2520</p>
                    </div>
                </div>
                <div className="main">
                    <div className="nameicons">
                        <div className="picons">
                            <CgOrganisation />
                        </div>
                        <div className="pname">
                            <p>Paid Sales</p>
                            <small>Center + Website</small>
                        </div>
                    </div>
                    <div className="pcounter">
                        <p>2250</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrganicPaid