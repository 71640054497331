import React from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BarChartProductOrder from '../components/BarChartProductOrder'
import PieChartProductOrder from '../components/PieChartProductOrder'
import BarChartHorizontaly from '../components/BarChartHorizontaly'
import DashboardSection from '../components/dashboard/DashboardOverview'
import DashboardOverview from '../components/dashboard/DashboardSection'
import GeoChart from '../components/dashboard/GeoChart'
import TopProductSale from '../components/dashboard/TopProductSale'
import MostPopularLocation from '../components/dashboard/MostPopularLocation'
import OrganicPaid from '../components/dashboard/OrganicPaid'

const Dashboard = () => {
    const navigate = useNavigate();
    const adminid = localStorage.getItem('admindata');
    useEffect(() => {
        if (adminid) {
            navigate('/dashboard');
        }
        else {
            navigate("/");
        }
    }, [adminid]);

    return (
        <div className="hrDashboard">
            <Helmet>
                <title>SATKARTAR:ADMIN::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                <div className="">
                    <DashboardOverview />
                </div>
                <div className="mt-2">
                    <DashboardSection />
                </div>
                {/*  Graph */}
                <div className="row row-cols-1 row-cols-md-6 row-cols-xl-12 graphsection">
                    <div className="col-md-8 card barChartbody">
                        <h6>Total Revenue</h6>
                        <div>
                            <BarChartProductOrder />
                        </div>
                    </div>
                    <div className="col-md-4 card polarCartBody">
                        <h6>Today's Sales</h6>
                        <div className="card-body">
                            <GeoChart />
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-6 row-cols-xl-12 graphsection">
                    <div className="col-md-8 card pieChartbody">
                        <h6>Yearly Sale's</h6>
                        <div>
                            <BarChartHorizontaly />
                        </div>
                    </div>
                    <div className="col-md-4 card">
                        <h6>Sales</h6>
                        <div>
                            <PieChartProductOrder />
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-6 row-cols-xl-12 productsection yearsection">
                    <div className="col-md-4">
                        <TopProductSale />
                    </div>
                    <div className="col-md-4 card">
                        <h6>Organic vs Paid</h6>
                        <div>
                            <OrganicPaid />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <MostPopularLocation />
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
