import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai'
import { FaCity, FaLocationDot } from 'react-icons/fa6'
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addRegistration, fetchHrDataFromSuperAdmin, getDataAPIToPincode } from '../utils/api'
import { FaHouseDamage } from 'react-icons/fa'
import { TbBuildingEstate, TbMapPinCode } from "react-icons/tb";
import { BiSolidHandRight } from "react-icons/bi";
import { GiIndiaGate } from "react-icons/gi";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


const AddAccount = () => {
    const navigate = useNavigate();
    const [pindata, setPindata] = useState([]);
    const [values, setUserValue] = useState({});
    const [hiddend, setHiddenValue] = useState({});
    const [company, setCompany] = useState([]);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        getActiveCompany()
    }, [])

    const getActiveCompany = () => {
        fetchHrDataFromSuperAdmin('super-admin-get-active-company').then((res) => {
            if (res.data.status === 1) {
                setCompany(...[res.data.company])
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }

    const handlePincode = e => {
        if (e.target.value.length === 6) {
            const pincode = e.target.value;
            getDataAPIToPincode('super-admin-get-data-according-to-pincode', pincode).then((res) => {
                if (res?.data.status === 1) {
                    setPindata(...[res?.data.pincode]);
                    setHiddenValue({ ...hiddend, account_city: res?.data.pincode.city_name, account_state: res?.data.pincode.state_name, account_country: res?.data.pincode.country_name });
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    }

    const handleInputs = async (e) => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddAccount = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const data = { account_name: values.account_name, account_mobile: values.account_mobile, account_email: values.account_email, account_pincode: values.account_pincode, account_city: hiddend.account_city, account_state: hiddend.account_state, account_country: hiddend.account_country, account_address: values.account_address, account_company: values.account_company }
            addRegistration('super-admin-add-account', data).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-account");
                    toast.success("Account has been added successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Account already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUPER ADMIN::ADD ACCOUNT</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-12">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaHouseDamage className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD ACCOUNT</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleAddAccount}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationCenterName">
                                        <Form.Label>User Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaHouseDamage /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="User Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_name"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid user name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterEmail">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiTwotoneMail /></InputGroup.Text>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter the email"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_email"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterMobile">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiFillMobile /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the mobile number"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_mobile"
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid mobile number.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterPincode">
                                        <Form.Label>Pincode</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbMapPinCode /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the pincode"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_pincode"
                                                pattern="[0-9]*"
                                                onKeyUp={handlePincode}
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid pincode.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomCity">
                                        <Form.Label>City</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaCity /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_city"
                                                readOnly={true}
                                                value={pindata?.city_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomState">
                                        <Form.Label>State</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbBuildingEstate /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="State"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_state"
                                                readOnly={true}
                                                value={pindata?.state_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomCountry">
                                        <Form.Label>Country</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><GiIndiaGate /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Country"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_country"
                                                readOnly={true}
                                                value={pindata?.country_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterCompany">
                                        <Form.Label>Select Company</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbBuildingEstate /></InputGroup.Text>
                                            <Form.Select aria-label="Default select example" onChange={handleInputs} name="account_company" required>
                                                <option value="">Select company</option>
                                                {company?.map((company, index) => (
                                                    <option value={company?.company_id} key={index}>{company?.company_name}</option>
                                                ))}

                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid company name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCenterAddress">
                                        <Form.Label>Address</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaLocationDot /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter the full address"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_address"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid address.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Add</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddAccount