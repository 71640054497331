import React, { useEffect, useState } from 'react'
import { FaBasketballBall, FaBuffer } from 'react-icons/fa'
import { MdCalendarMonth, MdOutlineCalendarMonth } from "react-icons/md";
import { IoToday } from "react-icons/io5";
import { toast } from 'react-toastify';
import { fetchAllDashboardDataAPI } from '../../utils/api';
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { numberFormat } from '../numberFormat';

const DashboardSection = () => {
    const [todayorder, setTodayOrder] = useState([]);
    const [yesterdayorder, setYesterdayOrder] = useState([]);
    const [thismonth, setThisMonthOrder] = useState([]);
    const [lastmonth, setLastMonthOrder] = useState([]);
    const [totalorder, setTotalOrder] = useState([]);
    

    useEffect(() => {
        getDashboardAllOrderTypeAgentOrder();
    }, [])

    const getDashboardAllOrderTypeAgentOrder = () => {
        fetchAllDashboardDataAPI('super-admin-get-dashboard-overview-order-type-data').then((res) => {
            if (res.data.status === 1) {
                setTodayOrder(...[res.data.todayorder]);
                setYesterdayOrder(...[res.data.yesterdayorder]);
                setThisMonthOrder(...[res.data.thismonth]);
                setLastMonthOrder(...[res.data.lastmonth]);
                setTotalOrder(...[res.data.totalorder]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
    return (
        <div className="dashboardsection">
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#4e9191" }}>
                <div className="icons">
                    <IoToday />
                </div>
                <div className="ordertype">
                    <span>Today Orders</span>
                </div>
                <div className="ordervalue">
                    <h2>{numberFormat(todayorder?.total_amount)}</h2>
                    <small><FaArrowRightArrowLeft /> {todayorder?.total_num}</small>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#0e7c52" }}>
                <div className="icons">
                    <FaBuffer />
                </div>
                <div className="ordertype">
                    <span>Yesterday Orders</span>
                </div>
                <div className="ordervalue">
                <h2>{numberFormat(yesterdayorder?.total_amount)}</h2>
                    <small><FaArrowRightArrowLeft /> {yesterdayorder?.total_num}</small>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#00CED1" }}>
                <div className="icons">
                    <MdCalendarMonth />
                </div>
                <div className="ordertype">
                    <span>This Months Orders</span>
                </div>
                <div className="ordervalue">
                <h2>{numberFormat(thismonth?.total_amount)}</h2>
                    <small><FaArrowRightArrowLeft /> {thismonth?.total_num}</small>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#c779db" }}>
                <div className="icons">
                    <MdOutlineCalendarMonth />
                </div>
                <div className="ordertype">
                    <span>Last Months Orders</span>
                </div>
                <div className="ordervalue">
                    <h2>{numberFormat(lastmonth?.total_amount)}</h2>
                    <small><FaArrowRightArrowLeft /> {lastmonth?.total_num}</small>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#597e0d" }}>
                <div className="icons">
                    <FaBasketballBall />
                </div>
                <div className="ordertype">
                    <span>Total Order</span>
                </div>
                <div className="ordervalue">
                <h2>{numberFormat(totalorder?.total_amount)}</h2>
                    <small><FaArrowRightArrowLeft /> {totalorder?.total_num}</small>
                </div>
            </div>
        </div>
    )
}

export default DashboardSection