import React from 'react'

const TopProductSale = () => {
    return (
        <div className="topagentproduct card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="headingtitle">
                        <h6>Top Selling Products</h6>
                    </div>
                    <div className="select">
                        <select
                            placeholder="Member Type"
                            name="product_date"
                            className="topagentselect"
                        >
                            <option value="Today">Today</option>
                            <option value="Yesterday">Yesterday</option>
                            <option value="Week">Week</option>
                            <option value="Month">Month</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="topagentproductsection">
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
                <div className="agentbody">
                    <span className="name">Addictin Killer</span>
                    <span className="tcount">250</span>
                </div>
            </div>
        </div>
    )
}

export default TopProductSale