import React from 'react'
import DatamapsIndia from "react-datamaps-india";


const GeoChart = () => {
    return (
        <div className="geochartsection">
            <DatamapsIndia
                regionData={{
                    Maharashtra: {
                        value: 10
                    },
                    Rajasthan: {
                        value: 1000
                    },
                    Gujarat: {
                        value: 800
                    },
                    Karnataka: {
                        value: 700
                    },
                    TamilNadu: {
                        value: 190
                    },
                    Kerala: {
                        value: 890
                    }
                }}
                hoverComponent={({ value }) => {
                    return (
                        <div>
                            <div>{value.name}</div>
                            <div>Total Sales:- {value.value}</div>
                        </div>
                    );
                }}
                mapLayout={{
                    title: "",
                    legendTitle: "",
                    startColor: "#c2d6b5",
                    endColor: "green",
                    hoverTitle: "Count",
                    noDataColor: "#f5f5f5",
                    borderColor: "#dddddd",
                    hoverBorderColor: "#dddddd",
                    hoverColor: "green",
                }}
            />
        </div>
    )
}

export default GeoChart