import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaRegHandPointRight, FaUserTie } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import { addHrRegistration, editDataFromList, updateDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai';

const EditSubAdmin = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setUserValue] = useState({
        subadmin_name: '',
        subadmin_mobile: '',
        subadmin_email: '',
    });
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        getSubAdminData(slug)
    }, [slug]);
    
    const getSubAdminData = (slug) => {
        editDataFromList(`super-admin-get-single-sub-admin-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setUserValue(...[res.data.subadmindata])
            }
        })
    }
    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                updateDataAPI(`super-admin-update-sub-admin-data/${slug}`, values).then((res) => {
                    if (res.data.status === 1) {
                        navigate('/all-subadmin');
                        toast.success("Sub Admin Data has been updated successfully!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };

    return (
        <div className="addHrPanel">
            <Helmet>
                <title>SATKARTAR:ADMIN::Add MIS</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaUserTie className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Sub Admin</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationName">
                                        <Form.Label>Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaUserTie /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="subadmin_name"
                                                onChange={handleInputs}
                                                value={values?.subadmin_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid chanel name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationChanelName">
                                        <Form.Label>Mobile</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiFillMobile /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Mobile"
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="subadmin_mobile"
                                                onChange={handleInputs}
                                                value={values?.subadmin_mobile}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid mobile number.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationChanelName">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiTwotoneMail /></InputGroup.Text>
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="subadmin_email"
                                                onChange={handleInputs}
                                                value={values?.subadmin_email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                </Row>
                                <Button type="submit">Submit</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditSubAdmin