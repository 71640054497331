import React, { useState } from 'react'
import logo from "../assets/logo.webp"
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AiOutlineDashboard, AiOutlineLogout, AiOutlineMenu, AiOutlineSetting, AiFillCloseCircle, AiFillControl } from "react-icons/ai";
import defaultUser from "../assets/avatar.webp";
import { FaLandmark, FaUser, FaUserGroup, FaUserTie } from "react-icons/fa6";
import { FaHouseDamage } from "react-icons/fa";
import { SiMisskey } from "react-icons/si";
import { FcAssistant } from "react-icons/fc";
import { CgChanel } from "react-icons/cg";
import { BiSolidUserAccount } from 'react-icons/bi';

const Sidebar = () => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const openMobileMenu = () => {
        if (mobileMenu) {
            setMobileMenu(false)
        }
        else {
            setMobileMenu(true)
        }
    }
    const navigate = useNavigate();
    function logout() {
        localStorage.clear();
        navigate("/");
    }
    return (
        <div className="wrapper">
            <div className="topbarSection">
                <div className="topbar">
                    <div className="mobile-toggle-menu">
                        <Link to="/dashboard">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <nav className="navbar navbar-expand">
                        <div className="user-box dropdown desktopDisplay">
                            <button className="d-flex align-items-center nav-link" role="button">
                                <div className="user-info ps-3">
                                    <div className="userimg">
                                        <img src={defaultUser} alt={defaultUser} />
                                    </div>
                                    <div className="username">
                                        <p className="user-name mb-0 dropdown-toggle" data-bs-toggle="dropdown">Super Admin</p>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="/profile"><AiOutlineSetting />Settings</Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" onClick={logout} to="javascript:void(0)"><AiOutlineLogout />LogOut</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="mobileDisplay">
                            <button onClick={openMobileMenu}>
                                {mobileMenu ?
                                    (
                                        <AiFillCloseCircle className="mobileMenuIcones" />
                                    )
                                    :
                                    (
                                        <AiOutlineMenu className="mobileMenuIcones" />
                                    )
                                }
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="sidebarWrapper desktopDisplay">
                <div className="simplebar-mask">
                    <div className="simplebar-offset">
                        <div className="simplebar-content-wrapper">
                            <div className="simplebar-content mm-active">
                                <ul className="metismenu mm-show" id="menu">
                                    <li>
                                        <NavLink activeClassName="active" to="/dashboard">
                                            <AiOutlineDashboard />
                                            <span>Dashboard</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/traffic">
                                            <FaUserGroup />
                                            <span>Traffic</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/hr">
                                            <FaUserGroup />
                                            <span>All HR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/all-employee">
                                            <FaUserTie />
                                            <span>All Employees</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/company">
                                            <FaLandmark />
                                            <span>All Company</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/ware-house">
                                            <FaHouseDamage />
                                            <span>All Ware House</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/mis">
                                            <SiMisskey />
                                            <span>All MIS</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/all-center">
                                            <FcAssistant />
                                            <span>All Center</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/all-subadmin">
                                            <FaUser />
                                            <span>All Sub Admin</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/all-subagent">
                                            <FaUser />
                                            <span>All Sub Agent</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/all-account">
                                            <BiSolidUserAccount />
                                            <span>All Accounts</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/all-channel">
                                            <CgChanel />
                                            <span>All Chanel</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${mobileMenu ? "" : "mobileMenuActive"}`}>
                <div className="mobilesidebarWrapper mobileDisplay">
                    <div className="simplebar-mask">
                        <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                                <div className="simplebar-content mm-active">
                                    <ul className="metismenu mm-show" id="menu">
                                        <li className="mm-active">
                                            <Link to="/dashboard">
                                                <AiOutlineDashboard />
                                                <span>Dashboard</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/hr">
                                                <FaUserGroup />
                                                <span>All HR</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/dashboard">
                                                <FaUserTie />
                                                <span>All Employees</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/company">
                                                <FaLandmark />
                                                <span>All Company</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/ware-house">
                                                <FaHouseDamage />
                                                <span>All Ware House</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/mis">
                                                <SiMisskey />
                                                <span>All MIS</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/all-center">
                                                <SiMisskey />
                                                <span>All Center</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/all-subagent">
                                                <FaUser />
                                                <span>All Sub Agent</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/dashboard">
                                                <AiFillControl />
                                                <span>All Roles</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link to="/dashboard">
                                                <AiOutlineSetting />
                                                <span>Settings</span>
                                            </Link>
                                        </li>
                                        <li className="mm-active">
                                            <Link onClick={logout} to="javascript:void(0)">
                                                <AiOutlineLogout />
                                                <span>LogOut</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
