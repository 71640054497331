import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import MobileOTP from "./pages/MobileOTP";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import HR from "./pages/HR";
import AddHr from "./pages/AddHr";
import EditHr from "./pages/EditHr";
import Company from "./pages/Company";
import AddCompany from "./pages/AddCompany";
import EditCompany from "./pages/EditCompany";
import WereHouse from "./pages/WereHouse";
import AddWereHouse from "./pages/AddWereHouse";
import MIS from "./pages/MIS";
import AddMis from "./pages/AddMis";
import EditMis from "./pages/EditMis";
import EditWereHouse from "./pages/EditWereHouse";
import Employee from "./pages/Employee";
import SubAgent from "./pages/SubAgent";
import AddSubAgent from "./pages/AddSubAgent";
import EditSubAgent from "./pages/EditSubAgent";
import 'react-loading-skeleton/dist/skeleton.css'
import CenterHead from "./pages/CenterHead";
import AddCenter from "./pages/AddCenter";
import EditCenter from "./pages/EditCenter";
import Chanel from "./pages/Chanel";
import AddChanel from "./pages/AddChanel";
import EditChanel from "./pages/EditChanel";
import Account from "./pages/Account";
import AddAccount from "./pages/AddAccount";
import EditAccount from "./pages/EditAccount";
import Traffic from "./pages/Traffic";
import SubAdmin from "./pages/subadmin/SubAdmin";
import AddSubAdmin from "./pages/subadmin/AddSubAdmin";
import EditSubAdmin from "./pages/subadmin/EditSubAdmin";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/traffic" element={<Traffic />} />
          <Route path="/hr" element={<HR />} />
          <Route path="/add-hr" element={<AddHr />} />
          <Route path="/edit-hr/:slug" element={<EditHr />} />
          <Route path="/company" element={<Company />} />
          <Route path="/add-company" element={<AddCompany />} />
          <Route path="/edit-company/:slug" element={<EditCompany />} />
          <Route path="/ware-house" element={<WereHouse />} />
          <Route path="/add-ware-house" element={<AddWereHouse />} />
          <Route path="/edit-ware-house/:slug" element={<EditWereHouse />} />
          <Route path="/mis" element={<MIS />} />
          <Route path="/add-mis" element={<AddMis />} />
          <Route path="/edit-mis/:slug" element={<EditMis />} />
          <Route path="/all-employee" element={<Employee />} />
          <Route path="/all-subagent" element={<SubAgent />} />
          <Route path="/add-sub-agent" element={<AddSubAgent />} />
          <Route path="/edit-sub-agent/:slug" element={<EditSubAgent />} />
          <Route path="/all-center" element={<CenterHead />} />
          <Route path="/add-center" element={<AddCenter />} />
          <Route path="/edit-center/:slug" element={<EditCenter />} />
          <Route path="/all-channel" element={<Chanel />} />
          <Route path="/add-channel" element={<AddChanel />} />
          <Route path="/edit-chanel/:slug" element={<EditChanel />} />
          <Route path="/all-account" element={<Account />} />
          <Route path="/add-account" element={<AddAccount />} />
          <Route path="/edit-account/:slug" element={<EditAccount />} />
          <Route path="/all-subadmin" element={<SubAdmin />} />
          <Route path="/add-sub-admin" element={<AddSubAdmin />} />
          <Route path="/edit-sub-admin/:slug" element={<EditSubAdmin />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
