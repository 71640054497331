import React from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BarChartProductOrder from '../components/BarChartProductOrder'
import PieChartProductOrder from '../components/PieChartProductOrder'
import BarChartHorizontaly from '../components/BarChartHorizontaly'
import DashboardSection from '../components/dashboard/DashboardOverview'
import DashboardOverview from '../components/dashboard/DashboardSection'
import GeoChart from '../components/dashboard/GeoChart'
import TopProductSale from '../components/dashboard/TopProductSale'
import MostPopularLocation from '../components/dashboard/MostPopularLocation'
import OrganicPaid from '../components/dashboard/OrganicPaid'
import TrafficSection from '../components/traffic/TrafficSection'
import SessionChart from '../components/traffic/SessionChart'

const Traffic = () => {
    const navigate = useNavigate();
    const adminid = localStorage.getItem('admindata');
    useEffect(() => {
        if (adminid) {
            navigate('/traffic');
        }
        else {
            navigate("/");
        }
    }, [adminid]);

    return (
        <div className="hrDashboard">
            <Helmet>
                <title>SATKARTAR:ADMIN::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                <div className="">
                    <TrafficSection />
                </div>
                {/*  Graph */}
                <div className="row row-cols-1 row-cols-md-6 row-cols-xl-12 graphsection">
                    <div className="col-md-6 card barChartbody">
                        <h6>Session</h6>
                        <div>
                            <SessionChart />
                        </div>
                    </div>
                    <div className="col-md-6 card polarCartBody">
                        <h6>Today's Sales</h6>
                        <div className="card-body">
                            <GeoChart />
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-6 row-cols-xl-12 graphsection yearsection">
                    <div className="col-md-8 card pieChartbody">
                        <h6>Yearly Sale's</h6>
                        <div>
                            <BarChartHorizontaly />
                        </div>
                    </div>
                    <div className="col-md-4 card">
                        <h6>Sales</h6>
                        <div>
                            <PieChartProductOrder />
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Traffic