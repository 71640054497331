import React from 'react';
import { Chart } from "react-google-charts";

export const data = [
    ["Task", "Hours per Day"],
    ["January", 11],
    ["February", 223],
    ["March", 222],
    ["April", 332],
    ["May", 756],
    ["June", 77],
    ["July", 743],
    ["August", 732],
    ["September", 127],
    ["October", 787],
    ["November", 798],
    ["December", 709],
];

export const options = {
    is3D: true,
};

const PieChartProductOrder = () => {
    return (
        <div className="piechart3d">
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"400px"}
            />
        </div>
    )
}

export default PieChartProductOrder