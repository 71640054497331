import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

const SessionChart = () => {
    const [data] = useState({
        labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Session',
                backgroundColor: '#001633',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 100,65, 59, 90, 81, 56, 55, 100],
            },
            {
                label: 'Page view',
                backgroundColor: '#85FFD0',
                borderColor: 'rgb(71, 225, 167)',
                data: [48, 78, 10, 99, 46, 87, 20,65, 59, 90, 81, 56, 55, 100],
            }
        ],
    });
    return (
        <div className="bg-white">
            <Bar data={data} options={{ responsive: true }} />
        </div>
    )
}

export default SessionChart